import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="prestige-classes"></a><h2>Prestige Classes</h2>
    <a id="blackguard"></a><h3>BLACKGUARD</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/dmg35_gallery/DMG35_PG182_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Hit Die</b>:
d10.</p>
    <a id="blackguard-requirements"></a><h6>Requirements</h6>
    <p className="initial">To qualify to become a blackguard, a character
must fulfill all the following criteria.</p>
    <p><b>Alignment</b>: Any evil.</p>
    <p><b>Base Attack Bonus</b>: +6.</p>
    <p><b>Skills</b>: Hide 5 ranks, Knowledge (religion) 2 ranks. </p>
    <p><b>Feats</b>: Cleave, Improved Sunder, Power Attack.</p>
    <p><b>Special</b>: The character must have made peaceful contact
with an evil outsider who was summoned by him or someone else. </p>
    <h6>Class Skills</h6>
    <p className="initial">The blackguard's class skills (and the key
ability for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#concentration">Concentration</a>
(Con), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#craft">Craft</a>
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#diplomacy">Diplomacy</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#handle-animal">Handle
Animal</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#heal">Heal</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#hide">Hide</a>
(Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#intimidate">Intimidate</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a>
(religion) (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#profession">Profession</a> (Wis), and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#ride">Ride</a>
(Dex). </p>
    <p><b>Skill Points at Each Level</b>: 2 + Int modifier.</p>
    <a id="table-the-blackguard"></a><p><b>Table: The Blackguard</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Level</th>
          <th rowSpan="2" style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Fort
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Ref
Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Will
Save</th>
          <th rowSpan="2" style={{
            "width": "40%"
          }}>Special</th>
          <th colSpan="4" style={{
            "textAlign": "center"
          }}>Spells per Day</th>
        </tr>
        <tr>
          <th style={{
            "width": "6%"
          }}>1st</th>
          <th style={{
            "width": "6%"
          }}>2nd</th>
          <th style={{
            "width": "6%"
          }}>3rd</th>
          <th style={{
            "width": "6%"
          }}>4th</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+1</td>
          <td>+2</td>
          <td>0</td>
          <td>0</td>
          <td>Aura of evil, detect good, poison use</td>
          <td>0</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+2</td>
          <td>+3</td>
          <td>0</td>
          <td>0</td>
          <td>Dark blessing, smite good 1/day</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+3</td>
          <td>+3</td>
          <td>+1</td>
          <td>+1</td>
          <td>Command undead, aura of despair</td>
          <td>1</td>
          <td>0</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+4</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
          <td>Sneak attack +1d6</td>
          <td>1</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+5</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
          <td>Fiendish servant, smite good 2/day</td>
          <td>1</td>
          <td>1</td>
          <td>0</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+6</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>
          </td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+7</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>Sneak attack +2d6</td>
          <td>2</td>
          <td>1</td>
          <td>1</td>
          <td>0</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+8</td>
          <td>+6</td>
          <td>+2</td>
          <td>+2</td>
          <td>
          </td>
          <td>2</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+9</td>
          <td>+6</td>
          <td>+3</td>
          <td>+3</td>
          <td>
          </td>
          <td>2</td>
          <td>2</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+10</td>
          <td className="last-row">+7</td>
          <td className="last-row">+3</td>
          <td className="last-row">+3</td>
          <td className="last-row">Sneak attack +3d6, smite good 3/day</td>
          <td className="last-row">2</td>
          <td className="last-row">2</td>
          <td className="last-row">2</td>
          <td className="last-row">1</td>
        </tr>
      </tbody>
    </table>
    <a id="blackguard-class-features"></a><h6>Class Features</h6>
    <p className="initial">All of the following are Class Features of the
blackguard prestige class.</p>
    <p><b>Weapon and Armor Proficiency</b>: Blackguards are
proficient with all simple and martial weapons, with all types of
armor, and with shields.</p>
    <a id="blackguard-aura-of-evil"></a><p><b>Aura of Evil (Ex)</b>: The power of a blackguard&#8217;s aura of
evil (see the detect evil spell) is equal to his class level plus his
cleric level, if any.</p>
    <p><b>Detect Good (Sp)</b>: At will, a blackguard can use detect
good as a spell-like ability, duplicating the effect of the detect good
spell.</p>
    <a id="blackguard-poison-use"></a><p><b>Poison Use</b>: Blackguards are skilled in the use of
poison and never risk accidentally poisoning themselves when applying
poison to a blade.</p>
    <a id="blackguard-dark-blessing"></a><p><b>Dark Blessing (Su)</b>: A blackguard applies his Charisma
modifier (if positive) as a bonus on all saving throws.</p>
    <p><b>Spells</b>: A blackguard has the ability to cast a small
number of divine spells. To cast a blackguard spell, a blackguard must
have a Wisdom score of at least 10 + the spell's level, so a blackguard
with a Wisdom of 10 or lower cannot cast these spells.</p>
    <p>Blackguard bonus spells are based on Wisdom, and saving throws
against these spells have a DC of 10 + spell level + the blackguard&#8217;s
Wisdom modifier. When the blackguard gets 0 spells per day of a given
spell level he gains only the bonus spells he would be entitled to
based on his Wisdom score for that spell level. The blackguard&#8217;s spell
list appears below. A blackguard has access to any spell on the list
and can freely choose which to prepare, just as a cleric. A blackguard
prepares and casts spells just as a cleric does (though a blackguard
cannot spontaneously cast cure or inflict spells).</p>
    <a id="blackguard-smite-good"></a><p><b>Smite Good (Su)</b>: Once a day, a blackguard of 2nd level
or higher may attempt to smite good with one normal melee attack.</p>
    <p>He adds his Charisma modifier (if positive) to his attack roll
and deals 1 extra point of damage per class level. If a blackguard
accidentally smites a creature that is not good, the smite has no
effect but it is still used up for that day.</p>
    <p>At 5th level, and again at 10th level, a blackguard may smite
good one additional time per day.</p>
    <a id="blackguard-aura-of-despair"></a><p><b>Aura of Despair (Su)</b>: Beginning at 3rd level, the
blackguard radiates a malign aura that causes enemies within 10 feet of
him to take a &#8211;2 penalty on all saving throws.</p>
    <p><b>Command Undead (Su)</b>: When a blackguard reaches 3rd
level, he gains the supernatural ability to command and rebuke undead.
He commands undead as would a cleric of two levels lower.</p>
    <p><b>Sneak Attack</b>: This ability, gained at 4th level, is
like the rogue ability of the same name. The extra damage increases by
+1d6 every third level beyond 4th (7th and 10th). If a blackguard gets
a sneak attack bonus from another source the bonuses on damage stack.</p>
    <a id="blackguard-spell-list"></a><h6>Blackguard Spell List</h6>
    <p className="initial">Blackguards choose their spells from the
following list:</p>
    <p>1st Level: cause fear, corrupt weapon, cure light wounds,
doom, inflict light wounds, magic weapon, summon monster I*.</p>
    <p>2nd Level: bull&#8217;s strength, cure moderate wounds, darkness,
death knell, eagle&#8217;s splendor, inflict moderate wounds, shatter, summon
monster II*.</p>
    <p>3rd Level: contagion, cure serious wounds, deeper darkness,
inflict serious wounds, protection from elements, summon monster III*.</p>
    <p>4th Level: cure critical wounds, freedom of movement, inflict
critical wounds, poison, summon monster IV*.</p>
    <p>* <i>Evil creatures only.</i></p>
    <a id="blackguard-corrupt-weapon"></a><h6>Corrupt Weapon</h6>
    <p className="initial">Blackguards have access to a special spell,
corrupt weapon, which is the opposing counterpart of the paladin spell
bless weapon. Instead of improving a weapon&#8217;s effectiveness against
evil foes corrupt weapon makes a weapon more effective against good
foes.</p>
    <a id="blackguard-fallen-paladins"></a><h6>Fallen Paladins</h6>
    <p className="initial">Blackguards who have levels in the paladin
class (that is to say, are now ex-paladins) gain extra abilities the
more levels of paladin they have.</p>
    <p>A fallen paladin who becomes a blackguard gains all of the
following abilities that apply, according to the number of paladin
levels the character has.</p>
    <p>1-2: Smite good 1/day. (This is in addition to the ability
granted to all blackguards at 2nd level.</p>
    <p>3-4: Lay on hands. Once per day, the blackguard can use this
supernatural ability to cure himself or his fiendish servant of damage
equal to his Charisma bonus x his level.</p>
    <p>5-6: Sneak attack damage increased by +1d6. Smite good 2/day.</p>
    <p>7-8: Fiendish summoning. Once per day, the blackguard can use
a summon monster I spell to call forth an evil creature. For this
spell, the caster level is double the blackguard's class level.</p>
    <p>9-10: Undead companion. In addition to the fiendish servant,
the blackguard gains (at 5th level) a Medium-size skeleton or zombie as
a companion. This companion cannot be turned or rebuked and gains all
special bonuses as a fiendish servant when the blackguard gains levels.
Smite good 3/day.</p>
    <p>11 or more: A fallen paladin of this stature immediately gains
a blackguard level for each level of paladin he trades in. </p>
    <p>The character level of the character does not change. With the
loss of paladin levels, the character no longer gains as many extra
abilities for being a fallen paladin. </p>
    <a id="blackguard-fiendish-servant"></a><h6>The Blackguard's Fiendish Servant</h6>
    <p className="initial">Upon or after reaching 5th level, a blackguard
can call a fiendish bat, cat, dire rat, horse, pony, raven, or toad to
serve him. The blackguard&#8217;s servant further gains HD and special
abilities based on the blackguard's character level (see the table
below).</p>
    <p>A blackguard may have only one fiendish servant at a time.</p>
    <p>Should the blackguard's servant die, he may call for another
one after a year and a day. The new fiendish servant has all the
accumulated abilities due a servant of the blackguard's current level.</p>
    <a id="table-the-blackguards-fiendish-servant"></a><table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th>Character Level</th>
          <th>Bonus HD</th>
          <th>Natural Armor Adj.</th>
          <th>Str Adj.</th>
          <th>Int</th>
          <th>Special</th>
        </tr>
        <tr className="odd-row">
          <td>12th or lower</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>6</td>
          <td>Empathic link, improved evasion, share saving throws,
share spells</td>
        </tr>
        <tr>
          <td>13th&#8211;15th</td>
          <td>+4</td>
          <td>+3</td>
          <td>+2</td>
          <td>7</td>
          <td>Speak with blackguard</td>
        </tr>
        <tr className="odd-row">
          <td>16th&#8211;18th</td>
          <td>+6</td>
          <td>+5</td>
          <td>+3</td>
          <td>8</td>
          <td>Blood bond</td>
        </tr>
        <tr>
          <td className="last-row">19th&#8211;20th</td>
          <td className="last-row">+8</td>
          <td className="last-row">+7</td>
          <td className="last-row">+4</td>
          <td className="last-row">9</td>
          <td className="last-row">Spell resistance</td>
        </tr>
        <tr>
          <td colSpan="6"><i>Character Level</i>: The character level
of the blackguard (his blackguard level plus his original class level).</td>
        </tr>
        <tr>
          <td colSpan="6"><i>Bonus HD</i>: Extra eight-sided (d8) Hit
Dice, each of which gains a Constitution modifier, as normal. Extra Hit
Dice improve the servant&#8217;s base attack and base save bonuses, as normal.</td>
        </tr>
        <tr>
          <td colSpan="6"><i>Natural Armor Adj.</i>: This is an
improvement to the servant&#8217;s existing natural armor bonus.</td>
        </tr>
        <tr>
          <td colSpan="6"><i>Str Adj.</i>: Add this figure to the
servant&#8217;s Strength score.</td>
        </tr>
        <tr>
          <td colSpan="6"><i>Int</i>: The servant&#8217;s Intelligence
score. (A fiendish servant is smarter than normal animals of its kind.)</td>
        </tr>
      </tbody>
    </table>
    <p>The abilities mentioned in the &#8220;Special&#8221; column of the
accompanying table are described below.</p>
    <p><b>Empathic Link (Su)</b>: The blackguard has an empathic link
with his servant out to a distance of up to 1 mile. The blackguard
cannot see through the servant&#8217;s eyes, but they can communicate
empathically. Because of the limited nature of the link, only general
emotional content can be communicated.</p>
    <p>Because of the empathic link between the servant and the
blackguard, the blackguard has the same connection to a place or an
item that the servant does.</p>
    <p><b>Improved Evasion (Ex)</b>: If the servant is subjected to
an attack that normally allows a Reflex saving throw for half damage,
it takes no damage on a successful saving throw and only half damage on
a failed saving throw. Improved evasion is an extraordinary ability.</p>
    <p><b>Share Saving Throws</b>: For each of its saving throws, the
servant uses either its own base save bonus or the blackguard&#8217;s,
whichever is higher. The servant applies its own ability modifiers to
saves, and it doesn&#8217;t share any other bonuses on saves that the
blackguard might have.</p>
    <p><b>Share Spells</b>: At the blackguard&#8217;s option, he may have
any spell (but not any spell-like ability) he casts on himself also
affect his servant. The servant must be within 5 feet at the time of
casting to receive the benefit. If the spell has a duration other than
instantaneous, it stops affecting the servant if it moves farther than
5 feet away and will not affect the servant again even if the servant
returns to the blackguard before the duration expires. Additionally,
the blackguard may cast a spell with a target of &#8220;You&#8221; on his servant
(as a touch range spell) instead of on himself. A blackguard and his
servant can share spells even if the spells normally do not affect
creatures of the servant&#8217;s type (magical beast).</p>
    <p><b>Speak with Blackguard (Ex)</b>: If the blackguard&#8217;s
character level is 13th or higher, the blackguard and servant can
communicate verbally as if they were using a common language. Other
creatures do not understand the communication without magical help.</p>
    <p><b>Blood Bond (Ex)</b>: If the blackguard&#8217;s character level is
16th or higher, the servant gains a +2 bonus on all attack rolls,
checks, and saves if it witnesses the blackguard being threatened or
harmed.</p>
    <p>This bonus lasts as long as the threat is immediate and
apparent.</p>
    <p><b>Spell Resistance (Ex)</b>: If the blackguard&#8217;s character
level is 19th or higher, the servant gains spell resistance equal to
the blackguard&#8217;s level + 5. To affect the servant with a spell, another
spellcaster must get a result on a caster level check (1d20 + caster
level) that equals or exceeds the servant&#8217;s spell resistance.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      